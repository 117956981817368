<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container materials">

            <h2>Общественные палаты регионов</h2>

            <div class="materials__tabs">
              <span v-for="(tab, index) in tabs" :class="{'active' : isActiveTab === index}" :key="`tab${index}`" @click="setTabNews(tab.route)">{{ tab.name }}</span>
            </div>

            <h3>Документы</h3>
            <ul class="documents__type-list">
              <li v-for="(type, key) of documentsType" class="documents__type-item"
                  :class="{'documents__active' : isActiveItem === key}"
                  :key="`type${key}`"
                  @click="ActiveItem(type, key)">
                {{ type }}
              </li>
            </ul>
          <h2 class="title-section" >{{ this.titleActive }}</h2>
          <documents-list v-for="item in document"
                          :key="`publicDocument${item.id}`"
                          class="documents__list"
                          :doc="item"
          />

        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import DocumentsList from '@/components/ChamberNormativeBaseDocuments'
import uniq from '@/methods/uniq'

export default {
  name: 'PublicChamberMaterials',
  components: {
    BreadCrumb,
    DocumentsList
  },
  data () {
    return {
      isActiveTab: 2,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Общественные палаты регионов'
        }
      ],
      tabs: [
        { name: 'Новости', route: '/public_chamber_news' },
        { name: 'Палаты по округам', route: '/public_chamber_regions' },
        { name: 'Документы', route: '/public_chamber_materials' }
      ],
      systemTagList: ['/public_doc'],
      document: [],
      documentsType: [],
      documentsAll: [],
      isActiveItem: -1,
      titleActive: 'Все'
    }
  },
  mounted () {
    this.setPlanDocuments()
    document.title = 'Общественные палаты регионов'
  },
  watch: {
    documentsType (value) {
      if (value.length) {
        this.ActiveItem(this.documentsType[0], 0)
      }
    }
  },
  methods: {
    ActiveItem (name, i) {
      this.isActiveItem = i
      this.titleActive = name
      this.document = []
      this.documentsAll.map(item => {
        if (item.tagList.includes(name)) {
          this.document.push(item)
        }
      })
    },
    setTabNews (route) {
      this.$router.push({ path: `${route}` })
    },
    async setPlanDocuments () {
      const formdata = new FormData()
      formdata.append('systemTagList', JSON.stringify(['/public_doc']))
      let res = await fetch(`${this.$store.getters.getUrlApi}api/document/list`, {
        method: 'POST',
        body: formdata
      })
      res = await res.json()
      this.documentsAll = res.data.documents
      this.document = res.data.documents
      const arrayTags = []
      this.document.map(item => {
        item.tagList.map(val => {
          arrayTags.push(val)
        })
      })
      this.documentsType = uniq(arrayTags)
    }
  }
}
</script>

<style lang="scss" scoped>

.white-container{
    width: 61.4rem;
    margin: 0 3.8rem;
    padding: 2.5rem 2.94rem;
}

h2{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 2rem;
}
.title-section {
  font-weight: 600;
  font-size: 1rem;
  color: #1F3245;
  margin-bottom: 2rem;
}
.documents {
  &__type-list {
    margin-left: 1.375rem;
    margin-bottom: 3.125rem;
  }
  &__type-item {
    list-style: disc;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #246CB7;

    cursor: pointer;
  }
  &__filter {
    margin-bottom: 1.875rem;
    display: inline-flex;
  }

  &__filter-select {
    width: 7.5rem;
  }

  &__filter-link {
    padding-left: 1.375em;
    display: flex;
    align-items: center;

    font-size: .75rem;
    line-height: 1.125rem;
    color: #003E78;
    opacity: 0.6;

    &::after {
      content: '';
      margin-left: 0.75rem;
      width: 0.7rem;
      height: 0.7rem;
      background-image: url('../../assets/icons/icon_link.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__list {
    margin-bottom: 2.5rem;
  }
  &__active {
    font-weight: 600;
  }
}
.documents__list{
  margin-bottom: 2rem;
}
h3{
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 1.375rem;

  color: #1F3245;
}

.materials {
  display: flex;
  flex-direction: column;

  &__tabs {
    display: flex;
    padding-bottom: 1.375rem;
    border-bottom: .0625rem solid #D5D6D9;
    margin-bottom: 2rem;
    color: #003E78;

    span, .active {
      font-size: 0.8125rem;
      margin-right: 1.25rem;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      opacity: 0.6;

      cursor: pointer;
    }

    .active {
      font-weight: 600;
      color: #246CB7;
      opacity: 1;
    }

    .active::before {
      content: '';
      height: 100%;
      width: 100%;
      bottom: -1.45rem;
      position: absolute;
      border-bottom: 0.125rem solid #246CB7;
    }

  }

}

@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }

}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;

        .materials__tabs {
          overflow-x: scroll;
          flex-wrap: nowrap;
          -ms-overflow-style: none;
          overflow: -moz-scrollbars-none;
          padding-bottom: 1.38rem;
          span{
            flex: 0 0 auto;
          }
          .active::before {
            content: '';
            height: 100%;
            width: 100%;
            bottom: -1.38rem;
          }
        }
    }
}
</style>
